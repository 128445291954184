const ArchiveMusicVideos2022Static = () => {
    return (
      <>
        <div class="music-video">

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/0PDDyumLrYo" title="SWAG SUPPLIER" allowfullscreen></iframe>
            </div>
            <p class="video-title-text">
            <strong>ANJU Online! & Kawaii Ry</strong><br/>
            SWAG SUPPLIER (feat. STARINTHESKY)
            </p>

            <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/pvQjdOQjEOM" title="Lugosi - ghostsinthewalls" allowfullscreen></iframe>
            </div>
            <p class="video-title-text">
            <strong>Lugosi</strong><br/>
            ghostsinthewalls (prod. ANJU Online!)
            </p>

            <div class="music-video-container">
                <iframe src="https://www.youtube.com/embed/Rg1IqE0vr7s" title="Hellscape Radio Vol. 3" allowfullscreen></iframe>
            </div>
            <p class="video-title-text">
                <strong>Josen</strong><br/>
                ZA [OFFICIAL ZAZA ANTHEM]
            </p> 
            
        </div>
      </>
    );
  };
  export default ArchiveMusicVideos2022Static;