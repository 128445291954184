const RileylolVideosStatic = () => {
    return (
      <>
        <div class="music-video"> 

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/viEzTBEkDMw" title="idontwannaseeyou" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>Hyder Daze - idontwannseeyou (feat. rileylol)</strong>
          </p>  

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/a5TkVrfFQP8" title="spine" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>spine</strong> (prod. jodom)
          </p>  

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/7YjcSy1XSxA" title="how u doing?" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>how u doing?</strong> (prod. quimmine)
          </p>  

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/igBUEgE6bQA" title="lost freestyle" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>lost freestyle</strong> (prod. elxnce)
          </p>                                                                                                                            
        
        </div>
      </>
    );
  };
  export default RileylolVideosStatic;