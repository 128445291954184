const Remy3dSinglesStatic = () => {
    return (
      <>
        <div class="gallery_containerThree-static">  

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://soundcloud.com/user-495687265/remy3d-dont-talk-about-we-feat-kawaiiry" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-KCftv46GjY0ybXmV-ys8mXg-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Don't Talk About We (feat. Kawaii Ry)</strong><br/>
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/7lS1xUQx6sHEudgrOLfZu3?si=0124317b5b24496b" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b27325e9ba744fa8abf7d19b0ba5" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Duffy (feat. Kawaii Ry)</strong><br/>
                (prod. Wellfed)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Single</p>
            <a href="https://open.spotify.com/track/6POWh3UdMlcAqNU5lHTgvm?si=296d88b3c2ac4a38" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b6df80eb65bc106fbea8042a" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Without You (feat. Kawaii Ry)</strong><br/>
                (prod. Wellfed)
              </p>
            </a>
          </div>

        </div>
      </>
    );
  };
  export default Remy3dSinglesStatic;