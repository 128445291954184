const ArchiveMusicVideos2020Static = () => {
    return (
      <>
        <div class="music-video">

        <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/7YjcSy1XSxA" title="how u doing?" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>rileylol</strong><br/>
            how u doing? (prod. quimmine)
          </p> 

          <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/kXeKwQsEtg4" title="Hellscape Radio Vol. 3" allowfullscreen></iframe>
            </div>
            <p class="video-title-text">
            <strong>Hellscape Radio, Vol. 3</strong><br/>
            (Hosted by ANJU Online!)
            </p>

            <div class="music-video-container">
            <iframe src="https://www.youtube.com/embed/igBUEgE6bQA" title="lost freestyle" allowfullscreen></iframe>
          </div>
          <p class="video-title-text">
            <strong>lost freestyle</strong><br/>
            (prod. elxnce)
          </p>    

        </div>
      </>
    );
  };
  export default ArchiveMusicVideos2020Static;