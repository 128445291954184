const RileylolSetsStatic = () => {
    return (
      <>
        <div class="gallery_containerThree-static"> 

        <div class="gallery_item">
            <p class="gallery-tag">Set</p>
            <a href="https://soundcloud.com/sadoutwest/krypzvania-set" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-23J13sRZxdwwDPkW-1RUoIw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>rileylol @ KRYPZVANIA (Full Set)</strong><br/>
              </p>
            </a>
          </div>                                                                                                                              
        
        </div>
      </>
    );
  };
  export default RileylolSetsStatic;