const MeauxminyPlacementsStatic = () => {
    return (
      <>
        <div class="gallery_containerThree-static">  

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/6JPdZVcrAGL1jrIbSYWwOQ?si=2fcb959ae9e34cab" target="_blank" rel="noopener noreferrer">
              <img src="https://i.imgur.com/AIZYHuT.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry, TRYFG & Boy Floss - Rare Link</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4C1UNfTBBlsstOX9rzuApo?si=95915470b7b3435b" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-il9NATXFnn8xquQe-onkrAQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - No Keys</strong><br/>
                (prod. Meauxminy & notamachine)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1cBIAPbkt6Mb4qrbTzrvuq?si=cd741088348c4148" target="_blank" rel="noopener noreferrer">
              <img src="https://i.scdn.co/image/ab67616d0000b273f2f2bf001f382b16315b7ac3" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry & Lugosi - Schoolin' (feat. Remy3D)</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/6HhQeMgYZ1LlatIMASuH3w?si=7a71d453a3b94d31" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Backend</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4tig1jSQUwK67VvKhA2OUk?si=e54ca3c2ae4d4144" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Pocky</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/67jt4fzuZIK8JzjStDXL3N?si=7c7d03837a424c9e" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Slippers</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2WChMqbDmN4ZNvTia8HpJU?si=f9ce53875320455a" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - ACSOM</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1Aru5QsDHrVu0mSejzoOJQ?si=9dc49be86fac4b11" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Appearances (feat. ReTread, Ivanko & Brole)</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/16NQubJdSSrjbjK2h4UPWv?si=25378694bab94477" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Circle Back</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/235GslmeWnm8issDYxeH1g?si=9042e42881d040ed" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Chop Walk</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4iHZ1g5m34xsqxqg92keim?si=58eb8de3a94e4f7e" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Thrill</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0Od6jyoTFaPHyWGbaB8ox5?si=7b0afedc66874df6" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Guild</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/0ar12H8rKtrtFR8Tx3rkHO?si=438e9f5f35644004" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273b12f570d61f237e614460ea7" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Credentials (BONUS)</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4tbBDrtDQ4HZ5GtXVeQvD8?si=2369eb1c2f914c9e" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d00001e02eb6ed43d5b7a864793e53697" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Noddin' Off</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://soundcloud.com/kawaiiry/kiki-krazy-prod-meauxminy" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-94Pwt3xAqVIRPzIA-rbwgLg-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - KiKi Crazy</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/5WcMFgBGwAprkavtzpgT0d?si=d7750df71d3e4001" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Dujour Speaks (feat. Lil Dujour)</strong><br/>
                (prod. Meauxminy & Geist)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1SeRmotpPjvM8LxLiA11Rj?si=007f074c964a445d" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Sacrifice</strong><br/>
                (prod. Meauxminy & Geist)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/7mUAYHUkJULXItbZoliZRH?si=7846219685714fe8" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Maybe Next Time</strong><br/>
                (prod. Meauxminy & Geist)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/6MSlRI0ke0lTBhfWHWA3r3?si=7b06d3ee01b149e6" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Spriggan</strong><br/>
                (prod. Meauxminy & Geist)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/1SERFTa6ndjpwY4b1fO4IB?si=7adc599a94dd46bd" target="_blank" rel="noopener noreferrer">
              <img src="https://i1.sndcdn.com/artworks-KSDnl3ihOK4mzmJM-n6cKLQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - 10/31 (feat. Boy Floss)</strong><br/>
                (prod. Meauxminy & Geist)
              </p>
            </a>
          </div>

        <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/07l0CzDWUaXY92KMQyl1BA?si=8fd23d53209445b6" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-M0Rzq7jdHWKlMJHT-ormDZw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - jesus jesus</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4VqjBT3mbx6qsP6jTHNSL2?si=fcd2def431cc467e" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-Zy2efuUGXKFOmrQy-zGctQw-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Speak Your Mind</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/6CWo5prwYmmRWsoc3Yfaqz?si=8d50dea961044df9" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-F34d7Juzp4RK1yh6-0zrTzQ-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Go Go Go</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/2Enla1jkgqw4BzcGej7vRK?si=0b54f52e83dc439c" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Mission Impossible</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4rJ6jGykNtPScdpnHeg5ya?si=7d932c631fe94b94" target="_blank" rel="noopener noreferrer">    
              <img src="https://i1.sndcdn.com/artworks-TNXzY0ZussIZo0q4-MWqNsg-t500x500.jpg" width="100%" alt=""/><br/>
              <p class="gallery-text">
                <strong>Kawaii Ry - Since You Left (feat. Nyquil)</strong><br/>
                (prod. Meauxminy)
              </p>
            </a>
          </div>

          <div class="gallery_item">
            <p class="gallery-tag">Placement</p>
            <a href="https://open.spotify.com/track/4OYLWFaPBDoIdzTXvuqrkp?si=ed0f2d3ea2f1427f" target="_blank" rel="noopener noreferrer">    
              <img src="https://i.scdn.co/image/ab67616d0000b273223eb10bd7246ab31582a07c" width="100%" alt=""/><br/>
              <p class="gallery-text">
              <strong>Kawaii Ry - Phone Off (feat. Killtheheretik)</strong><br/>
              (prod. Meauxminy)
              </p>
            </a>
          </div>

        </div>
      </>
    );
  };
  export default MeauxminyPlacementsStatic;